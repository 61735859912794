import React from "react"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from "../../styles/components/bread-crumb.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

export default function Meta( props ) {
  return (
    <div className={styles.breadCrumb}>
      <ul className={styles.breadCrumbInner}>
        {/* ToDo: 以下後で戻す Linkに戻す */}
        <li className={styles.breadCrumbInnerList}><a href="/"><StaticImage src={'../../images/ico-home.svg'} alt="ホーム" placeholder="blurred" width={24} /></a></li>
          { (props.parentLink && props.parentTitle) ?
            <li className={styles.breadCrumbInnerList}><Link to={props.parentLink}>{props.parentTitle}</Link></li> :
            ''
          }
        <li className={styles.breadCrumbInnerList} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(props.currentTitle) }} />
      </ul>
    </div>
  )
}