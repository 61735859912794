import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// プロジェクトページコンポーネント
import { getCurrentProject, MainImage, ProjectTab, ProjectSideMenu } from "../../components/parts/project"

const ProjectArticlePage = ({ data }) => {
  const pageType = 'overview'
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const {
    slug,
    title,
    outline,
    features,
    featuresBottom,
    subImage,
  } = frontmatter
  const { description } = getCurrentProject(slug)

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        ogImage={`https://${process.env.HOST_NAME}/images${slug}og.png`}
        path={slug}
      />

      <BreadCrumb
        parentLink="/project/"
        parentTitle="プロジェクト情報"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
      </div>
    
      <main className={styles.articleContents}>
        <article className={styles.articleContentsLeft}>
          <MainImage slug={slug} current={pageType} alt={title} />
          <ProjectTab current={pageType} slug={slug} />

          <div id="projectOutline" className={`${styles.tabPane} ${styles.activePane}`}>
            <div className={styles.articleInner}>
              <h2>プロジェクト概要</h2>
              { outline.map((item, key) => {
                return <p key={`project-outline-${key}`} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(item) }} />
              })}
            </div>
            { (subImage !== null) ? 
              <div className="text-center">
                <img className={styles.subImage} src={subImage} alt="" />
              </div>
              : ''
            }
            <div className={styles.articleInner}>
              <h2>プロジェクトの特徴</h2>
              {
                features.map((item, key) => {
                  const { title, img, content, imgLarge } = item
                  return (img) ? <React.Fragment key={`project-feature-${key}`}>
                      <h3>{title}</h3>
                      <div className={styles.projectFeature}>
                      <div className={`${styles.projectFeaturePhoto} ${ imgLarge ? styles.large : '' }`}><img src={img} alt={title} /></div>
                        <p dangerouslySetInnerHTML={{ __html: specialCharacterReplace(content)}} />
                      </div>
                      </React.Fragment>
                      : 
                      <React.Fragment key={`project-feature-${key}`}>
                        <h3 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
                        <div className={styles.projectFeature}>
                          <p dangerouslySetInnerHTML={{ __html: specialCharacterReplace(content)}} />
                        </div>
                      </React.Fragment>
                })
              }
              <div dangerouslySetInnerHTML={{ __html: specialCharacterReplace(featuresBottom)}} />
            </div>
          </div>

        </article>
        
        <ProjectSideMenu slug={slug} type="/" current={pageType} />
      </main>
    </Layout>
  )
}
export default ProjectArticlePage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        outline
        features {
          content
          title
          img
          imgLarge
        }
        featuresBottom
        subImage
      }
    }
  }
`